@import url("https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap");

.login-form {
  margin-top: 15px !important;
  width: 450px;
}
.login-form .ant-row {
  display: block;
  margin-left: 30px;
  margin-right: 25px;
}
.left-login-block {
  margin-top: auto;
  margin-bottom: auto;
  text-align: -webkit-center;
}

.right-login-block {
  margin-top: 100px;
}

.login-btn-color {
  background-color: #2c7be5;
}
.login-form-forgot {
  color: #7d8592;
  font-size: 12px;
}

.welcome-text {
  color: #263446;
  font-size: 20px;
  font-weight: 600;
  display: grid;
  text-align: center;
}

.login-text {
  color: #5e81f4;
  font-size: 40px;
  font-weight: 600;
  text-align: left;
  margin-bottom: 20px;
}

.login-input {
  line-height: 40px;
  border: 0px;
  width: 100%;
  height: 42px !important;
  border-radius: 4px !important;
}
.for-rem {
  margin-top: -5%;
  margin-left: -7px;
}
.for-title {
  font-size: 24px;
  width: 100%;
  /* height: 30px; */
  padding-top: 20px;
  /* margin-left: 15px; */
}
.for-but {
  width: 50%;
}
.for-forget {
  margin-top: -5%;
}
.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: none;
}

.for-user {
  margin-left: -45%;
  width: 125%;
  height: 48px !important;
}
.for-dont {
  margin-left: 15px;
}
.for-pass {
  margin-left: -32%;
  width: 115%;
}
.do-you .ant-form-item-control-input-content {
  text-align: center;
  margin-top: 20px;
}

.for-logo {
  margin-top: 50px;
}

.for-img {
  margin-top: 60px;
  margin-right: 62px;
  margin-bottom: 59px;
  margin-left: 61px;
}
.do-you-text {
  color: #b5b6b6;
  margin-right: 10px;
}
.ant-form-item-label label {
  padding-top: 10px !important;
  font-family: "Open Sans", sans-serif;
  color: #7d8592;
  font-size: 15px;
  font-weight: 400;
}

.log_form_main {
  background-color: white;
  width: 450px;
  margin-top: 5%;
  height: max-content;
  padding-bottom: 32px;
  overflow-y: auto;
  margin-left: auto !important;
  margin-right: auto !important;
  border-radius: 16px;
  box-shadow: 0px 6px 58px 0px #c4cbd61a;
}
.login_logo {
  margin-left: auto;
  margin-right: auto;
}
.sub_title {
  font-size: 16px;
  font-weight: 400;
  color: #718096;
}
.login-input .ant-input {
  margin-top: 0px;
  margin-left: 5px;
  font-size: 15px;
}
.ant-input-prefix .site-form-item-icon {
  font-size: 20px;
  color: #7d8592;
}
.ant-input-suffix .site-form-item-icon {
  font-size: 20px;
  color: #7d8592;
}
.login-form-button {
  height: 45px;
  font-size: 16px;
  border-radius: 4px;
}
.text-right .ant-form-item-control-input-content {
  margin-top: -25px;
}

.login_alerts {
  margin-left: 30px;
  margin-right: 25px;
  margin-top: 15px;
}
.ant-form-item-explain.ant-form-item-explain-error {
  margin-top: -15px;
  margin-bottom: 15px;
}
.ant-form-item-label > label::after {
  content: "";
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}
.show_pass {
  cursor: pointer;
}
.m-t--20 {
  margin-top: -16px;
}
.login_alerts .close {
  font-size: 2.3rem;
  font-weight: 350;
  margin-top: 3px;
}
input.new-pass-inp-pad {
  padding-left: 15px;
}
.setup-pass-eye-icon {
  margin-left: -32px;
  font-size: 18px !important;
  margin-bottom: 7px;
}
.checkbox-container {
  position: relative;
}

.checkbox-container input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  display: flex;
  height: 16px;

  background-color: #ffffff;
  border: 2px solid #7d8592;
  border-radius: 4px;
  outline: none;
}
.checkbox-container {
  margin-left: 7.5%;
  display: flex;
  align-items: center;
}
.log-rem-label {
  margin-top: 8px;
  margin-left: 4px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #7d8592;
}
@media (max-width: 1200px) {
  .checkbox-container {
    margin-left: 5%;
  }
}

/* Customize tick color */
.checkbox-container input[type="checkbox"]:checked::before {
  content: "\2713";
  display: inline-block;
  width: 24px;
  height: 12px;
  background-color: white;
  text-align: center;
  line-height: 12px;
  font-size: 11px;
  font-weight: 1000;
  color: #3f8cff;
}

/* Customize background color when checked */
.checkbox-container input[type="checkbox"]:checked {
  background-color: white;
}
.verify-user-alert {
  color: #ab1f00 !important;
  background-color: #f2482226 !important;
  margin-top: 20px;
}
.log-top-alert.fade.alert-danger {
  width: 450px;
  color: #ab1f00;
}
.verify-user-otp-block {
  overflow: auto;
  height: max-content !important;
}
